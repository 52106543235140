<template lang="pug">
  PostForm(:data="data")
  //- pre {{ data }}
</template>

<script>
import PostForm from './PostForm'

export default {
  name: 'PostAdd',
  components: {
    PostForm
  },
  data () {
    return {
      data: {
        id: undefined
      }
    }
  },
  methods: {
    submit() {
      this.$http.post('post', this.detail).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    }
  }
  //- beforeRouteLeave (to, from, next) {
  //-   const component = this.$refs['form'];
  //-   const { detail, detail_init } = component;
  //-   if (JSON.stringify(detail) === JSON.stringify(detail_init)) return next();
    
  //-   this.$Modal.confirm({
  //-     title: `提示`,
  //-     content: `内容已修改，是否保存`,
  //-     closable: true,
  //-     onOk: () => component.handleSubmit('form'),
  //-     onCancel: () => next(),
  //-   });
  //- }
}
</script>

<style lang="less" scoped></style>